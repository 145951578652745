import { NgxLoggerLevel } from 'ngx-logger';
import { DefaultConfig } from 'src/assets/default.config';

export const environment = {
  production: true,
  ngxLoggerConfig: {
    logging: {
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.INFO,
      LoggerColorScheme: ['purple', 'gray', 'black', 'green', 'orange', 'red', 'red'],
      // TODO: change in production.
      proxiedSteps: 1,
      serverLoggingUrl: DefaultConfig.uris.logs,
      enableSourceMaps: false
    }
  }
};

/**
 * Cache busting Build ID. To include in urls for requesting assets that might be updated.
 * Following line with buildId will be appended during build by CI.
 */
//export const buildId = "";
